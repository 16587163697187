<template>
  <div class="Attract-position">
    <div class="position-title">
      <h1 class="label-input">{{$t("scheduleAttPosition.title")}}</h1>
    </div>
    <div class="position-form">
      <v-container fluid>
        <v-row
            align="center"
        >
          <v-col
              cols="12"
              sm="12"
          >
            <v-select
                dense
                :items="items"
                small-chips
                :label="$t('scheduleAttPosition.name')"
                multiple
                outlined
                item-text="name"
                item-value="id"
                v-model="position_id"
            ></v-select>
          </v-col>
          <v-col cols="6" md="6">
            <v-btn class="btn-save-change" @click="saveChange" :loading="btnLoading">
              {{$t("scheduleAttPosition.save")}}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  data() {
    return {
      btnLoading: false,
      items: [],
      position_id: [],
    }
  },
  methods: {
    fetchPosition() {
      this.$axios.get(`company/all-position`).then((res) => {
        this.items = res.data.data;
      }).catch(() => {

      })
    },


    saveChange() {
      const id = this.$route.params.schedule_id;
      this.$axios.post(`company/check-in-schedule/${id}/attach`, {
        position_id: this.position_id
      }).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$router.push({
              name: "scheduleAttractPosition.index"
            })
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300)
        }
      })
    }
  },
  created() {
    this.fetchPosition();
  }
}
</script>

<style scoped lang="scss">
.Attract-position {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  flex-direction: column;
  .position-title{
    h1{
      font-weight: 400;
    }
  }

  .position-form {
    width: 600px;
    height: 400px;
    //background-color: #719AFF;
  }
}


</style>
